import { useSelector } from 'react-redux';

const excludePaths = ['sync'];

const ClassIndicator = () => {
  const group = useSelector(s => s.group);
  const { groups } = useSelector(s => s.school);
  const selectorGroup = groups?.find(g => g.groupId === group.groupId);

  if (excludePaths.find(path => window.location.pathname.includes(path)))
    return null;

  return (
    <span className="text-muted font-weight-normal ml-2">
      {selectorGroup?.code ?? 'All Classes'}
    </span>
  );
};

export default ClassIndicator;
