import { cache } from 'store';
import { Api } from 'api';
import types from './types';

const getOrAddAsync = async ({ callback, props, defaultReturn = null }) =>
  await cache.getOrAddAsync({
    ...props,
    callback: async () => {
      try {
        return await callback();
      } catch (e) {
        console.error(e);
      }
      return defaultReturn;
    }
  });

class CachedApi {
  static async getQuizInsights(quizId) {
    return await getOrAddAsync({
      props: {
        key: `quiz-insights:${quizId}`,
        typename: types.quizInsights.typename,
        ttlMinutes: 60 * 24,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getQuizInsights(quizId),
      defaultReturn: null
    });
  }

  static async getTeacherAssignmentInsights(
    teacherId,
    assignmentGroup,
    groupId,
    page,
    take,
    includes = [],
    sort
  ) {
    return await getOrAddAsync({
      props: {
        key:
          `getLegacyInsights-teacher:${teacherId}:group:${groupId}:` +
          `assignmentGroup:${assignmentGroup}:includes-${includes.join(':')}` +
          `:page-${page}:take-${take}:sort-${JSON.stringify(sort)}`,
        typename: types.assignmentInsights.typename,
        ttlMinutes: 2,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getTeacherAssignmentInsights(
          teacherId,
          assignmentGroup,
          groupId,
          page,
          take,
          includes,
          sort
        ),
      defaultReturn: null
    });
  }

  static async getTeacherAssignmentInsightsForAssignment(
    teacherId,
    assignmentId,
    includes
  ) {
    return await getOrAddAsync({
      props: {
        key: `getLegacyInsightsForAssignment:assignmentId:${assignmentId}:teacherId:${teacherId}:includes-${includes.join(
          ':'
        )}`,
        typename: types.assignmentInsights.typename,
        ttlMinutes: 2,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getTeacherAssignmentInsightsForAssignment(
          teacherId,
          assignmentId,
          includes
        ),
      defaultReturn: null
    });
  }

  static async getQuiz(quizId) {
    return await getOrAddAsync({
      props: {
        key: `getQuiz:${quizId}`,
        typename: types.quiz.typename,
        ttlMinutes: 60 * 24,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getQuiz(quizId),
      defaultReturn: null
    });
  }

  static async searchTopicPathwayCollectionQuiz(
    {
      searchText = '',
      topicPathwayCollectionIds = [],
      subjectIds = [],
      yearGroupId = null,
      topicSearchText = null,
      sortFilter = null,
      includeNoInterventionTopics = true,
      page = 0,
      pageSize = 20,
      locale = null,
      topicTagIds = [],
      parentTopicTagIds = []
    },
    signal
  ) {
    const skip = page * pageSize;
    const take = pageSize;

    return await getOrAddAsync({
      props: {
        key: `searchTopicPathwayCollectionQuiz::searchText:${searchText}:topicPathwayCollectionIds:${topicPathwayCollectionIds.join(
          ','
        )}:yearGroupId:${yearGroupId}:subjectId:${subjectIds?.join(
          ','
        )}:topicSearchText:${topicSearchText}:sortFilter:${sortFilter}:includeNoInterventionTopics:${includeNoInterventionTopics}:skip:${skip}:take:${take},
        :locale:${locale}:topicTagIds:${topicTagIds.join(
          ','
        )}:parentTopicTagIds:${parentTopicTagIds.join(',')}`,
        typename: types.collectionSearch.typename,
        ttlMinutes: 5,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.searchTopicPathwayCollectionQuiz(
          {
            searchText,
            topicPathwayCollectionIds,
            yearGroupId,
            subjectIds,
            topicSearchText,
            sortFilter,
            includeNoInterventionTopics,
            skip,
            take,
            locale,
            topicTagIds,
            parentTopicTagIds
          },
          signal
        ),
      defaultReturn: { data: [], count: 0 }
    });
  }

  static async getTopicTags(
    locale = null,
    parentId = null,
    topicPathwayCollectionIds = []
  ) {
    return await getOrAddAsync({
      props: {
        key: `getTopicTags:locale:${locale}:parentId:${parentId}:topicPathwayCollectionIds:${topicPathwayCollectionIds.join(
          ','
        )}`,
        typename: types.topicTags.typename,
        ttlMinutes: 15,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getTopicTags(locale, parentId, topicPathwayCollectionIds),
      defaultReturn: []
    });
  }

  static async getTopicPathwayQuizFromShortCode(
    shortCode,
    includes = ['quiz']
  ) {
    return await getOrAddAsync({
      props: {
        key: `getTopicPathwayQuizFromShortCode:${shortCode}:${JSON.stringify(
          includes
        )}`,
        typename: types.topicPathwayQuiz.typename,
        ttlMinutes: 60 * 24,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getTopicPathwayQuizFromShortCode(shortCode, includes),
      defaultReturn: null
    });
  }

  static async getTopicInsights(params) {
    return await getOrAddAsync({
      props: {
        key: `getTopicInsights:${JSON.stringify(params)}`,
        typename: types.topicInsights.typename,
        ttlMinutes: 2,
        isUseSessionStorage: true
      },
      callback: async () => {
        // There's no way of filtering topic insights by groupId, so instead switch the endpoint if there's a groupId to get insights just for the class
        if (params.groupId) {
          return await Api.getGroupStudentTopicInsights(params);
        }
        return await Api.getTopicInsights(params);
      },
      defaultReturn: null
    });
  }

  static async getGroupAnalytics(params) {
    return await getOrAddAsync({
      props: {
        key: `getGroupAnalytics:${JSON.stringify(params)}`,
        typename: types.groupAnalytics.typename,
        ttlMinutes: 10,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getGroupAnalytics(params),
      defaultReturn: null
    });
  }

  static async getGroupRetrievalPractice(groupId) {
    return await getOrAddAsync({
      props: {
        key: `getGroupRetrievalPractice:${groupId}`,
        typename: types.retrievalPractice.typename,
        ttlMinutes: 1,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getGroupRetrievalPractice(groupId),
      defaultReturn: null
    });
  }

  static async getStudentAnalytics(params) {
    return await getOrAddAsync({
      props: {
        key: `getStudentAnalytics:${JSON.stringify(params)}`,
        typename: types.studentAnalytics.typename,
        ttlMinutes: 10,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getStudentAnalytics(params),
      defaultReturn: null
    });
  }

  static async getNotifications(
    userId,
    status,
    schoolId,
    groupId = null,
    page = 1,
    limit = 30
  ) {
    return await getOrAddAsync({
      props: {
        key: `getNotifications:userId:${userId}:status:${status}:schoolId:${schoolId}:groupId:${groupId}:page:${page}:limit:${limit}`,
        typename: types.notifications.typename,
        ttlMinutes: 10,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getNotifications(
          userId,
          status,
          schoolId,
          groupId,
          page,
          limit
        ),
      defaultReturn: null
    });
  }

  static async searchB2BTopicPathwayCollectionQuiz({
    searchText = '',
    topicPathwayCollectionIds = [],
    yearGroupId = null,
    subjectIds = [],
    sortFilter = null,
    includeNoInterventionTopics = true,
    skip = 0,
    take = 9999,
    token = null
  }) {
    return await getOrAddAsync({
      props: {
        key: `searchB2BTopicPathwayCollectionQuiz:searchText:${searchText}:topicPathwayCollectionIds:${topicPathwayCollectionIds.join(
          ','
        )}:yearGroupId:${yearGroupId}:subjectId:${subjectIds?.join(
          ','
        )}:sortFilter:${sortFilter}:includeNoInterventionTopics:${includeNoInterventionTopics}:skip:${skip}:take:${take}`,
        typename: 'searchB2BTopicPathwayCollectionQuiz',
        ttlMinutes: 30,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.searchB2BTopicPathwayCollectionQuiz({
          searchText,
          topicPathwayCollectionIds,
          yearGroupId,
          subjectIds,
          sortFilter,
          includeNoInterventionTopics,
          skip,
          take,
          token
        }),
      defaultReturn: { data: [], count: 0 }
    });
  }

  static async getB2BTopicPathwayCollections(token) {
    return await getOrAddAsync({
      props: {
        key: `getB2BTopicPathwayCollections:year-groups`,
        typename: 'getB2BTopicPathwayCollections',
        ttlMinutes: 30,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getB2BTopicPathwayCollections('year-groups', token),
      defaultReturn: []
    });
  }

  static async getYearGroups(locale = 'en-GB') {
    return await getOrAddAsync({
      props: {
        key: `getYearGroups:${locale}`,
        typename: 'year-groups',
        ttlMinutes: 30,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getYearGroups(locale),
      defaultReturn: []
    });
  }

  static async getDepartments(schoolId) {
    return await getOrAddAsync({
      props: {
        key: `getDepartments:${schoolId}`,
        typename: 'departments',
        ttlMinutes: 30,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getDepartments(schoolId),
      defaultReturn: []
    });
  }

  static async getSchoolYearGroups(schoolId) {
    return await getOrAddAsync({
      props: {
        key: `getSchoolYearGroups:${schoolId}`,
        typename: 'year-groups',
        ttlMinutes: 30,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getSchoolYearGroups(schoolId),
      defaultReturn: []
    });
  }

  static async getQuizInferences({
    quizIds = [],
    topicPathwayCollectionId = null,
    topicTagIds = [],
    parentTopicTagIds = [],
    countryYearGroupIds = [],
    userIds = [],
    groupId = null
  }) {
    return await getOrAddAsync({
      props: {
        key: `getQuizInferences:quizIds:${quizIds.join(
          ','
        )}:topicPathwayCollectionId:${topicPathwayCollectionId}:topicTagIds:${topicTagIds.join(
          ','
        )}:parentTopicTagIds:${parentTopicTagIds.join(
          ','
        )}:countryYearGroupIds:${countryYearGroupIds.join(
          ','
        )}:userIds:${userIds.join(',')}:groupId:${groupId}`,
        typename: types.getQuizInferences.typename,
        ttlMinutes: 10,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getQuizInferences({
          quizIds,
          topicPathwayCollectionId,
          topicTagIds,
          parentTopicTagIds,
          countryYearGroupIds,
          userIds,
          groupId
        }),
      defaultReturn: []
    });
  }
}

export default CachedApi;
