import {
  Button,
  Modal,
  Card,
  Row,
  Col,
  EediPlusLogo,
  EmojiSpan,
  Switch,
  toaster
} from 'components/common';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { media } from 'styles';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getImageUrl } from 'config/settings';
import ChallengeGold from 'assets/images/ChallengeGold';
import ChallengeSilver from 'assets/images/ChallengeSilver';
import ChallengeBronze from 'assets/images/ChallengeBronze';
import GlobalRankPill from 'pages/classLeague/components/GlobalRankPill';
import StreakPointsPill from 'pages/classLeague/components/StreakPointsPill';
import { MotivationFeature } from 'constants/motivationFeatures';
import { Api } from 'api';
import { updateSchool } from 'actions/school';
import { hasDynamicFeature } from 'helpers/featureFlags';

const Motivate = () => {
  const isAvatarShopCardEnabled =
    hasDynamicFeature('MotivateAvatarShopCard') === 'Enabled';
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(null);
  const [motivationOpts, setMotivationOpts] = useState([]);
  const { schoolId, motivationalFeatureFlagOptionsList } = useSelector(s => ({
    schoolId: s.school.schoolId,
    motivationalFeatureFlagOptionsList:
      s.school.currentSchool.motivationalFeatureFlagOptionsList || []
  }));
  const code = useSelector(s => s.group.code);

  const toggleSchoolFeature = useCallback(
    async feature => {
      try {
        const updatedOpts = !motivationOpts.includes(feature)
          ? [...new Set([...motivationOpts, feature])]
          : motivationOpts.filter(f => f !== feature);
        const response = await Api.updateMotivationalFeatures(
          schoolId,
          updatedOpts
        );
        if (response) {
          setMotivationOpts(updatedOpts);
          dispatch(
            updateSchool({ motivationalFeatureFlagOptionsList: updatedOpts })
          );
          return;
        }
        throw new Error(`Error setting ${feature}`);
      } catch (e) {
        toaster.danger(`Failed to update avatar shop settings - ${e}`);
        console.error(e);
      }
    },
    [dispatch, motivationOpts, schoolId]
  );

  useEffect(() => {
    setMotivationOpts(motivationalFeatureFlagOptionsList);
  }, [motivationalFeatureFlagOptionsList]);

  return (
    <>
      <Row className="mb-3">
        <Col lg="9">
          <p className="text-xl">
            Eedi has motivation tools baked in. Challenges, Coins, virtual
            assets in the Eedi shop all help to improve class effort and
            engagement.
          </p>
          <p className="text-md">
            You can find detailed information about our motivational tools and
            school league in our help center{' '}
            <EmojiSpan emoji={'👉'} ariaLabel={'pointer'} />{' '}
            <Link
              to="https://help.eedi.co.uk/en/?q=motivate"
              target="_blank"
              rel="noopener noreferrer">
              #motivate
            </Link>{' '}
            <Link
              to="https://help.eedi.co.uk/en/?q=league"
              target="_blank"
              rel="noopener noreferrer">
              #league
            </Link>{' '}
            <Link
              to="https://help.eedi.co.uk/en/?q=set+work"
              target="_blank"
              rel="noopener noreferrer">
              #setwork
            </Link>
          </p>
        </Col>
      </Row>

      <Modal
        size="lg"
        isOpen={modalOpen === 'classLeague'}
        toggle={() => setModalOpen(null)}>
        <Modal.Header toggle={() => setModalOpen(null)}>
          <h3 className="mb-1">Class League</h3>
          <h4 className="subtitle text-muted">
            How this looks for your students
          </h4>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <img
            src={getImageUrl('eedi/images/motivate/class-league.png')}
            alt="How Class League looks for your students"
            className="w-100"
          />
        </Modal.Body>
      </Modal>
      {isAvatarShopCardEnabled ? (
        <Card
          className="border-0 mb-4"
          style={{ backgroundColor: 'var(--green-lighter' }}>
          <Card.Body>
            <Row>
              <Col xs="4" md="3" xl="3">
                <img
                  src="https://home-content.eedi.com/web/shop.png"
                  alt="The Eedi Shop"
                  className="w-100 rounded"
                  // crop image slightly
                  style={{ objectPosition: '0 0' }}
                />
              </Col>
              <Col xs="8" md="9" xl="9">
                <h2>Avatar Shop</h2>
                <p>
                  Students earn coins for completing activities in lessons. They
                  can spend these coins on competitions or the avatar shop.
                </p>
                <p>
                  It is currently a default requirement that students have a
                  parent before being able to access the shop. To remove this
                  requirement you can turn on the toggle below.
                </p>
                <div style={{ display: 'flex', margin: '0 0 1rem 0' }}>
                  <Switch
                    value={
                      !!motivationOpts.includes(
                        MotivationFeature.AvatarsSansParents
                      )
                    }
                    onClick={() =>
                      toggleSchoolFeature(MotivationFeature.AvatarsSansParents)
                    }
                  />
                </div>
                <strong>
                  {motivationOpts.includes(MotivationFeature.AvatarsSansParents)
                    ? 'Students can access the shop without a parent'
                    : 'Students need a parent to access the shop (default)'}
                </strong>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}
      <Card
        className="border-0 mb-4"
        style={{ backgroundColor: 'var(--green-lighter' }}>
        <Card.Body>
          <Row>
            <Col xs="4" md="3" xl="3">
              <img
                src={getImageUrl(
                  'eedi/images/motivate/school-league-thumb.png'
                )}
                alt="Class League"
                className="w-100 rounded"
              />
            </Col>
            <Col xs="8" md="9" xl="9">
              <h2>Global School League</h2>
              <p>
                Students earn streak points for completing activities, each week
                those near the top of the league (students with the highest
                streak points) will be awarded with bonus coins!
              </p>
              <p>
                Students can also see how their school places in the league vs
                other schools.
              </p>
              <p>
                Look out for the flame icon next to student, classes and schools
                to show the accumulated streak points.
              </p>
              <div style={{ display: 'flex', margin: '-0.4rem 0 0.6rem 0' }}>
                <StreakPointsPill
                  points={99}
                  style={{ maxWidth: '50px', border: '1px solid #bbb' }}
                  tooltip="This example has 99 streak points!"
                />
              </div>
              <Button
                to={`/${schoolId}/motivate/school-league`}
                color="primary">
                View Global School League
              </Button>
              <p style={{ margin: '1rem 0 0 0' }}>
                <Link onClick={() => setModalOpen('schoolLeague')}>
                  How this looks for your students
                </Link>
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card
        className="border-0 mb-4"
        style={{ backgroundColor: 'var(--orange-lighter)' }}>
        <Card.Body>
          <Row>
            <Col xs="4" md="3" xl="3">
              <img
                src={getImageUrl('eedi/images/motivate/class-league-thumb.png')}
                alt="Class League"
                className="w-100 rounded"
              />
            </Col>
            <Col xs="8" md="9" xl="9">
              <h2>Class & School League</h2>
              <p>
                Students can compete with their fellow students by earning
                streak points, they are encouraged to complete topics to
                overtake the student ahead of them, only competing with the
                students directly ahead of them in the league.
              </p>
              <p>
                Look out for the global rank icon next to student names in the
                class league pages.
              </p>
              <div style={{ display: 'flex', margin: '-0.4rem 0 0.6rem 0' }}>
                <GlobalRankPill
                  rank={1}
                  style={{ maxWidth: '50px', border: '1px solid #bbb' }}
                  tooltip="This example is ranked 1st in the global student leaderboard"
                />
              </div>
              <Button
                to={
                  code
                    ? `/${schoolId}/motivate/class-league`
                    : `/${schoolId}/motivate/group-league`
                }
                color="primary">
                {code ? `View ${code}'s Class League` : 'View My School League'}
              </Button>
              <p style={{ margin: '1rem 0 0 0' }}>
                <Link onClick={() => setModalOpen('classLeague')}>
                  How this looks for your students
                </Link>
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card
        className="border-0 mb-4"
        style={{ backgroundColor: 'var(--red-lighter)' }}>
        <Card.Body>
          <Row className="g-3">
            <Col xs="4" md="3" xl="3">
              <img
                src={getImageUrl(
                  'eedi/images/motivate/class-challenge-2-thumb.png'
                )}
                alt="Class Challenge"
                className="w-100 rounded"
              />
            </Col>
            <Col xs="8" md="9" xl="9">
              <h2>Class Challenge</h2>
              <p>
                We'll award your students with coins based on the whole classes
                completion rate, encouraging them to work together to complete
                quizzes. Motivate your students by aiming for 100% on every
                quiz!
              </p>
              <p>
                Coins can be collected or used to buy avatar items in our shop
              </p>
              <BadgePills>
                <BadgePill>
                  <ChallengeBronze /> 100 coin bonus
                </BadgePill>
                <BadgePill>
                  <ChallengeSilver />
                  200 coin bonus
                </BadgePill>
                <BadgePill>
                  <ChallengeGold /> 600 coin bonus
                </BadgePill>
              </BadgePills>
              <Button to={`/${schoolId}`} color="primary">
                View assignment results
              </Button>
              <p style={{ margin: '1rem 0 0 0' }}>
                <Link onClick={() => setModalOpen('classChallenge')}>
                  How this looks for your students
                </Link>
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card
        className="border-0 mb-4"
        style={{ backgroundColor: 'var(--gray-lightest)' }}>
        <Card.Body>
          <Row className="g-3">
            <Col xs="4" md="3" xl="3">
              <img
                src={getImageUrl('eedi/images/motivate/prize-draw-thumb.png')}
                alt="Prize Draws"
                className="w-100 rounded"
              />
            </Col>
            <Col xs="8" md="9" xl="9">
              <div className="mb-2">
                <strong>
                  Eedi <EediPlusLogo />
                </strong>
              </div>
              <h2>Prize Draws</h2>
              <p>
                We reward learning effort with Eedi coins, which can be spent on
                prize draw entries for a chance to win tickets, vouchers,
                gadgets and more. We've found this to increase motivation and
                effort amongst our students.
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal
        size="lg"
        isOpen={modalOpen === 'classChallenge'}
        toggle={() => setModalOpen(null)}>
        <Modal.Header toggle={() => setModalOpen(null)}>
          <h3 className="mb-1">Class Challenge</h3>
          <h4 className="subtitle text-muted">
            How this looks for your students
          </h4>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <img
            src={getImageUrl('eedi/images/motivate/class-challenge-2.png')}
            alt="How Class Challenge looks for your students"
            className="w-100"
          />
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        isOpen={modalOpen === 'schoolLeague'}
        toggle={() => setModalOpen(null)}>
        <Modal.Header toggle={() => setModalOpen(null)}>
          <h3 className="mb-1">School league</h3>
          <h4 className="subtitle text-muted">
            How this looks for your students
          </h4>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <img
            src={getImageUrl('eedi/images/motivate/school-league.png')}
            alt="How the School League looks for your students"
            className="w-100"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const BadgePills = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.md`
    display: flex;
    flex-direction: row;
    align-items: center;
`}
`;

const BadgePill = styled.div`
  background-color: var(--white);
  border-radius: 99rem;
  padding: 0.125rem 0.75rem 0.125rem 2.75rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 0.75rem;
  position: relative;
  white-space: nowrap;
  svg {
    position: absolute;
    top: -0.75rem;
    left: -0.25rem;
  }
`;

export default Motivate;
