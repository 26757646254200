import gql from 'graphql-tag';

export default gql`
  mutation moveStudents($input: MoveStudentsInput) {
    moveStudents(input: $input) {
      groupId
      studentCount
    }
  }
`;
