import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = ({ children, ...props }) => {
  let Tag = styled.span`
    cursor: pointer;
    color: var(--primary);
    font-weight: 500;
  `;
  if (props.to) {
    Tag = RouterLink;
  } else if (props.href) {
    Tag = styled.a``;
  }

  return <Tag {...props}>{children}</Tag>;
};

export default Link;
