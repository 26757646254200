import './globals';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import client from 'config/apolloClient';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import AppRoutes from 'routes/AppRoutes';
import { persistor, store } from './store';
import AppProviders from 'providers/AppProviders';
import ScrollToTop from 'components/common/ScrollToTop';
import { CookieProvider } from 'components/cookies/CookieProvider';

const App = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CookieProvider>
          <BrowserRouter>
            <ScrollToTop />
            <ErrorBoundary>
              <AppProviders>
                <AppRoutes />
              </AppProviders>
            </ErrorBoundary>
          </BrowserRouter>
        </CookieProvider>
      </PersistGate>
    </Provider>
  </ApolloProvider>
);

export default App;
