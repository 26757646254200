/* @flow */
import compose from 'lodash/flowRight';
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import { Button } from 'components/common';
import { deleteTeacher, verifyTeacher } from 'graphql/mutations';

import analytics from 'analytics';

class VerifyTeacherAction extends PureComponent {
  state = {
    loading: false
  };

  handleVerifyClick = () => {
    this.setState({ loading: true });
    analytics.track('Teacher Approved', { category: 'Notifications' });
    this.props
      .verifyTeacher(this.props.data.teacherId)
      .then(() => this.props.onDismiss())
      .catch(() => this.setState({ loading: false }));
  };

  handleRejectClick = () => {
    this.setState({ loading: true });
    analytics.track('Teacher Denied', { category: 'Notifications' });
    this.props
      .deleteTeacher(this.props.data.teacherId)
      .then(() => this.props.onDismiss())
      .catch(() => this.setState({ loading: false }));
  };

  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        <Button
          outline
          size="small"
          onClick={this.handleRejectClick}
          loading={loading}>
          Decline
        </Button>
        &nbsp;
        <Button
          color="primary"
          size="small"
          onClick={this.handleVerifyClick}
          loading={loading}>
          Accept
        </Button>
      </React.Fragment>
    );
  }
}

export default compose(
  graphql(deleteTeacher, {
    props: ({ mutate }) => ({
      deleteTeacher: teacherId => mutate({ variables: { teacherId } })
    })
  }),
  graphql(verifyTeacher, {
    props: ({ mutate }) => ({
      verifyTeacher: teacherId =>
        mutate({
          variables: { teacherId }
        })
    })
  })
)(VerifyTeacherAction);
