import gql from 'graphql-tag';

export default gql`
  mutation addGroup($schoolId: ID!, $input: AddGroupInput!) {
    addGroup(
      schoolId: $schoolId
      input: $input
      includes: ["teachers", "year-groupids"]
    ) {
      groupId
      code
    }
  }
`;
