import tracking from 'analytics/tracking';
import { Button, Container, Form, Input } from 'components/common';
import useMount from 'hooks/useMount';
import { ModalTypes, useSearchParamModal } from 'hooks/useSearchParamModal';
import { useState } from 'react';
import styled from 'styled-components';

const OnboardingAssignment = () => {
  const { navigateToModal } = useSearchParamModal();
  const [searchText, setSearchText] = useState('');

  useMount(() => {
    tracking.track('Handheld Assignment Page Viewed');
  });

  const handleSearch = e => {
    e.preventDefault();
    tracking.track('Handheld Assignment Searched', {
      searchText
    });
    navigateToModal({
      modalType: ModalTypes.SetWork,
      meta: {
        stage: 'topics',
        search: searchText
      }
    });
  };

  return (
    <Body>
      <Container className="container-xs">
        <div className="text-center mb-4">
          <h2>Welcome to Eedi</h2>
          <h3 className="subtitle">Let's set your first assignment</h3>
        </div>
        <h1 className="text-center">What topic are you teaching right now?</h1>
        <Form onSubmit={handleSearch}>
          <Input
            type="text"
            placeholder="Algebra, fractions, etc."
            value={searchText}
            onClick={() =>
              tracking.track('Handheld Assignment Searchbox Clicked')
            }
            onChange={e => setSearchText(e.target.value)}
            className="mb-3"
          />
          <Button type="submit" color="primary" size="large" block>
            Find a quiz
          </Button>
        </Form>
      </Container>
    </Body>
  );
};
const Body = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export default OnboardingAssignment;
