import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import tracking from 'analytics/tracking';
import murmur from 'murmurhash-js';
import {
  hasFeature,
  hasDynamicFeature,
  featureToggle
} from 'helpers/featureFlags';
import { consoleHelper } from 'helpers/consoleHelper';
import { round } from 'lodash';
import useQuery from './useQuery';

let tracked = [];

const useDynamicFeature = ({
  action = null,
  isTracking = false,
  isABTesting = false,
  dynamicFeature = null,
  userIdOverride = null,
  seedOverride = null,
  cohortDescription = ['IsControlGroup', 'IsTreatmentGroup'],
  defaultFeatureStatus = featureToggle.NotSet,
  properties = {},
  abTestDateSettings = {
    startDate: null,
    endDate: null
  }
}) => {
  const userId = useSelector(s => s.user?.userId);
  const userIdentifier = userIdOverride || userId;
  const seed = seedOverride ?? dynamicFeature ?? 'Seed';
  const hashCode = murmur.murmur3(`${seed}`, parseInt(userIdentifier, 10));
  const isTreatmentGroup = !(hashCode % 2);
  const [isFeatureTrackingDispatched, setIsFeatureTrackingDispatched] =
    useState(false);
  let featureStatus = hasDynamicFeature(dynamicFeature);
  const overrideParam = useQuery().get(dynamicFeature);

  if (
    overrideParam &&
    [
      featureToggle.NotSet,
      featureToggle.Disabled,
      featureToggle.Enabled
    ].includes(overrideParam)
  ) {
    featureStatus = overrideParam;
  }

  if (featureStatus === featureToggle.NotSet) {
    featureStatus = !hasFeature(dynamicFeature)
      ? featureToggle.NotSet
      : featureToggle.Enabled;
  }

  // hasDynamicFeature function will return 'NotSet' if no feature flag found.
  // It will return 'Disabled' if it's explicitly disabled.
  // It will return 'Enabled' if it's explicity enabled.
  // If it's set as 'Enabled' ALL users see this feature.
  // If it's set as 'Disabled' NO users see this feature.
  // If it's set as 'NotSet' then the AB testing works out who see's it.
  isABTesting =
    featureStatus !== featureToggle.Disabled
      ? isABTesting && isWithinPeriod(abTestDateSettings, dynamicFeature)
      : false;

  const isFeatureEnabled =
    featureStatus === featureToggle.Disabled
      ? false
      : featureStatus === featureToggle.Enabled
      ? true
      : isABTesting
      ? isTreatmentGroup
      : featureStatus === featureToggle.NotSet &&
        (defaultFeatureStatus === featureToggle.Enabled ||
          defaultFeatureStatus === featureToggle.NotSet)
      ? true
      : false;

  console.log(`dynamic teacher feature status ${dynamicFeature}`, {
    featureStatus,
    isFeatureEnabled,
    isABTesting,
    isTreatmentGroup,
    userIdentifier,
    hashCode,
    seed,
    overrideParam,
    hasFeature: hasFeature(dynamicFeature),
    isWithinPeriod: isWithinPeriod(abTestDateSettings, dynamicFeature),
    abTestDateSettings
  });

  const track = useCallback(
    async (action, properties = {}) => {
      await tracking.track('Dynamic Teacher Feature', {
        ...properties,
        action,
        is_ab_testing: isABTesting,
        seed,
        dynamic_feature: dynamicFeature,
        dynamic_feature_status: featureStatus,
        is_feature_enabled: isFeatureEnabled,
        is_treatment_group: isTreatmentGroup,
        cohort_description: cohortDescription[+isTreatmentGroup]
      });
    },
    [
      cohortDescription,
      dynamicFeature,
      featureStatus,
      isABTesting,
      isFeatureEnabled,
      isTreatmentGroup,
      seed
    ]
  );

  const dispatchTracking = useCallback(
    async (isTracking = true, action, properties) => {
      if (
        !action ||
        isFeatureTrackingDispatched ||
        !isTracking ||
        !userId ||
        tracked.includes(dynamicFeature)
      )
        return;

      // Only track this dynamic feature once
      !tracked.includes(dynamicFeature) && tracked.push(dynamicFeature);

      consoleHelper.warn({ source: 'isDispatched', action });
      track(action, properties);
      setIsFeatureTrackingDispatched(true);
    },
    [isFeatureTrackingDispatched, dynamicFeature, track, userId]
  );

  useEffect(() => {
    dispatchTracking(isTracking, action, properties);
  }, [action, dispatchTracking, isTracking, properties]);

  return {
    isFeatureEnabled,
    isTreatmentGroup,
    userId,
    hashCode,
    isFeatureTrackingDispatched,
    dispatchTracking: (action, properties) =>
      dispatchTracking(true, action, properties),
    track
  };
};

const isWithinPeriod = (dateSettings, dynamicFeature) => {
  if (!dateSettings?.startDate || !dateSettings?.endDate) return true;

  try {
    const { startDate, endDate } = dateSettings;
    let start = new Date(startDate);
    let end = new Date(endDate);
    let now = new Date();

    if (now < start) {
      let startDays = new Date(start - now);
      startDays = startDays / 1000 / 60 / 60 / 24;
      startDays = round(startDays, 0);
      console.log(`days until start date -> ${dynamicFeature}`, startDays);
    }

    return now >= start && now <= end;
  } catch (e) {
    console.error(e);
  }

  return false;
};

export default useDynamicFeature;
