import gql from 'graphql-tag';

export default gql`
  mutation updateGroupStudents(
    $groupId: ID!
    $schoolId: ID!
    $input: [GroupStudent]!
  ) {
    updateGroupStudents(groupId: $groupId, schoolId: $schoolId, input: $input) {
      groupId
      code
    }
  }
`;
