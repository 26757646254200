import tracking from 'analytics/tracking';
import { Button } from 'components/common';
import { ModalTypes, useSearchParamModal } from 'hooks/useSearchParamModal';

const SetWorkButton = ({
  groupId = null,
  location = null,
  tag = null,
  children,
  ...props
}) => {
  const { navigateToModal } = useSearchParamModal();

  const buttonText = 'Set work';
  const Component = tag ?? Button;

  const handleClick = e => {
    e.preventDefault();

    tracking.track(tracking.eventNames.SetWorkClicked, {
      location
    });

    navigateToModal({
      modalType: ModalTypes.SetWork,
      meta: {
        stage: 'topics',
        groupId
      }
    });
  };

  return (
    <Component onClick={handleClick} {...props}>
      {children ?? buttonText}
    </Component>
  );
};

export default SetWorkButton;
