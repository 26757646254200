import { Helmet } from 'react-helmet';
import { Container } from 'components/common';
import styled from 'styled-components';
import { media } from 'styles';

const Page = ({ title = '', container, children, ...props }) => (
  <>
    <Helmet>
      <title>{title && title !== '' ? title + ' - Eedi' : 'Eedi'}</title>
    </Helmet>
    <StyledMain {...props}>
      {container ? (
        <Container className={`container-${container}`}>{children}</Container>
      ) : (
        <>{children}</>
      )}
    </StyledMain>
  </>
);

Page.Main = ({ container, children, ...props }) => (
  <StyledMain {...props}>
    {container ? <Container>{children}</Container> : <>{children}</>}
  </StyledMain>
);

const StyledMain = styled.div`
  position: relative;
  padding: 0 0.5rem;
  margin-bottom: 5rem;
  ${media.md`
    padding: 0 2rem;
  `};
`;

export default Page;
