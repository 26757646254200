import styled from 'styled-components';
import { useState } from 'react';
import NotificationsDropdown from 'components/notifications/NotificationsDropdown';
import { Dropdown } from 'components/common';
import { useSelector } from 'react-redux';
import { Api, useApiQuery } from 'api';
import { NotificationsIcon } from 'assets/icons';
import tracking from 'analytics/tracking';
import CachedApi from 'cache/CachedApi';
import analytics from 'analytics';
import { cache } from 'store';
import types from 'cache/types';

const NotificationsButton = () => {
  const [isOpen, setIsOpen] = useState();

  const userId = useSelector(s => s.user.userId);
  const schoolId = useSelector(s => s.school.currentSchool.schoolId);

  const { data, loading, reload } = useApiQuery(async () => {
    const dashboardNotifications = await CachedApi.getNotifications(
      userId,
      'DashboardMessage',
      schoolId,
      null,
      1,
      999
    );

    const pastNotifications = await CachedApi.getNotifications(
      userId,
      'Past',
      schoolId,
      null,
      1,
      999
    );

    return {
      data: [...dashboardNotifications.data, ...pastNotifications.data],
      total: [dashboardNotifications.total + pastNotifications.total]
    };
  }, [userId, schoolId]);

  const handleDismiss = async id => {
    analytics.track('Notification Dismissed', { category: 'Notifications' });
    cache.clearType({ typename: types.notifications.typename });
    await Api.dismissNotification(id);
    reload();
  };

  const trackClick = type => {
    tracking.track('Interacted With A Notification', {
      type
    });
  };

  const toggle = () => {
    if (!isOpen) {
      tracking.track('Clicked Notifications Dropdown');
    }
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown id="notifications" isOpen={isOpen} toggle={toggle}>
      <StyledDropdownToggle active={isOpen} outline>
        {data?.total > 0 && <UnreadIndicator>{data.total}</UnreadIndicator>}
        <NotificationsIcon size="21" />
      </StyledDropdownToggle>
      <StyledDropdownMenu right>
        {isOpen && (
          <NotificationsDropdown
            data={data}
            onDismiss={handleDismiss}
            onClick={trackClick}
            loading={loading}
          />
        )}
      </StyledDropdownMenu>
    </Dropdown>
  );
};

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  border-radius: 99rem;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  width: 500px;
  max-height: 80vh;
  overflow-y: scroll;
  border: 1px solid var(--gray-lighter);
  padding: 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
`;

const UnreadIndicator = styled.span`
  background-color: var(--red);
  height: 1rem;
  min-width: 1rem;
  padding: 0 0.125rem;
  border-radius: 1rem;
  position: absolute;
  top: -0.0625rem;
  right: -0.25rem;
  color: var(--white);
  font-size: 0.75rem;
  line-height: 1rem;
`;

export default NotificationsButton;
