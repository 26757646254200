import styled from 'styled-components';
import CalendarEmptyImg from 'assets/images/empty_calendar.svg';

const TopicCalendar = ({ endDateUtc, size = '4rem', ...props }) => {
  return (
    <>
      {endDateUtc ? (
        <Calendar size={size} {...props}>
          <Month>{endDateUtc.format('MMM') ?? ''}</Month>
          <Day>{endDateUtc.format('D') ?? ''}</Day>
        </Calendar>
      ) : (
        <img {...props} src={CalendarEmptyImg} alt="Calendar Empty" />
      )}
    </>
  );
};

const Calendar = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 99rem;
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0.625rem;
  background-color: var(--green-lighter);
  align-self: center;
`;

const Day = styled.div`
  width: 100%;
  flex: 1;
  background-color: var(--white);
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0 0 0.25rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Month = styled.div`
  width: 100%;
  height: 0.85rem;
  flex: 0 0 0.85rem;
  background-color: var(--green);
  color: var(--green-lighter);
  font-weight: 500;
  font-size: 0.65rem;
  text-align: center;
  padding: 0.125rem;
  line-height: 1;
  border-radius: 0.25rem 0.25rem 0 0;
`;

export default TopicCalendar;
