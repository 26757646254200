import gql from 'graphql-tag';

export default gql`
  mutation createDepartments(
    $departments: [NewDepartmentInput]
    $schoolId: ID!
  ) {
    createDepartments(departments: $departments, schoolId: $schoolId) {
      departmentId
      name
    }
  }
`;
