import styled from 'styled-components';
import { media } from 'styles';
import { Container } from 'components/common';

const Header = ({ container, children, ...props }) => {
  return (
    <StyledHeader tint={props.tint?.toString()} {...props}>
      <Container className={container ? `container-${container}` : ''}>
        {children}
      </Container>
    </StyledHeader>
  );
};

Header.Title = styled.div`
  flex: 1;
  margin: 3rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  h1 {
    margin: 0;
  }
  h2 {
    margin: 0.5rem 0;
    font-size: 1.4rem;
    font-weight: normal;
    color: var(--gray-dark);
  }
`;

Header.Nav = styled.div`
  ${media.md`
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
  margin-bottom: 1rem;
`;

Header.Separator = styled.div`
  height: 0.5rem;
  ${media.md`
    border-left: 1px solid var(--border-color);
    width: 1px;
    margin: 0 2rem;
    height: 2.5rem;
  `}
`;

const StyledHeader = styled.div`
  flex-flow: column wrap;
  ${media.lg`
     flex-flow: row wrap;
  `}
  ${props =>
    props.tint &&
    `
    background-color: var(--tint-color);
    border-bottom: 1px solid var(--border-color);
  `};
  display: flex;
`;

export default Header;
