import { useEffect } from 'react';

/*
Inspired by https://github.com/streamich/react-use/blob/master/src/useMount.ts
*/

const useEffectOnce = effect => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

const useMount = fn => {
  useEffectOnce(() => {
    fn();
  });
};

export default useMount;
