import { closedSearchParamModal } from 'actions/ui';
import { objectToSearchParams } from 'helpers/urlSearchParamsHelper';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ModalTypes = {
  SetWork: 'set-work',
  AddStudents: 'add-student-classes'
};

const useSearchParamModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const getModalRouteParam = (modalType, relativePath) =>
    `/${modalType}/${relativePath}`;

  // Navigate to 'blank' clears search params
  const closeModal = returnPath => {
    returnPath ? navigate(returnPath) : navigate();
    dispatch(closedSearchParamModal());
  };

  const navigateToModal = ({ modalType, meta = {}, baseUrl = null }) => {
    let modalPath;

    switch (modalType) {
      case ModalTypes.SetWork:
        modalPath = getModalRouteParam(
          ModalTypes.SetWork,
          meta.quizShortCode
            ? `${meta.stage}/${meta.quizShortCode}`
            : meta.stage
        );
        break;

      case ModalTypes.AddStudents:
        modalPath = getModalRouteParam(ModalTypes.AddStudents, `${meta.stage}`);
        break;

      default:
        break;
    }

    if (modalPath) {
      let payload = {
        modal: modalPath
      };

      if (meta.groupId) payload['groupId'] = meta.groupId;
      if (meta.search) payload['search'] = meta.search;

      if (baseUrl) {
        const path = `${baseUrl}?${objectToSearchParams(payload).toString()}`;

        navigate(path, { replace: true });
      } else {
        setSearchParams(payload);
      }
    }
  };

  return {
    navigateToModal,
    closeModal
  };
};

export { useSearchParamModal, ModalTypes };
