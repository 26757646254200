import styled from 'styled-components';
import { media } from 'styles';
import NotificationsButton from 'components/notifications/NotificationsButton';

const Header = ({ children, showNotifications = false, ...props }) => (
  <StyledHeader {...props}>
    <Container>
      {showNotifications ? (
        <NotificationsWrapper>
          <NotificationsButton />
        </NotificationsWrapper>
      ) : null}
      <Children>{children}</Children>
    </Container>
  </StyledHeader>
);

const Container = styled.div`
  width: 100%;
`;

Header.Title = styled.div`
  margin: 2rem 0 1rem 0;
  min-height: 3.5rem;
  h1 {
    margin: 0;
  }
  ${media.md`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`;

Header.Nav = styled.div`
  ${media.md`
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
  margin-bottom: 1rem;
`;

Header.Separator = styled.div`
  height: 0.5rem;
  ${media.md`
    border-left: 1px solid var(--border-color);
    width: 1px;
    margin: 0 2rem;
    height: 2.5rem;
  `}
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.tint &&
    `
    background-color: var(--tint-color);
    border-bottom: 1px solid var(--border-color);
  `};

  ${media.md`
    flex-direction: row;
  `}

  .container {
    position: relative;
  }
`;

const Children = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotificationsWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 2rem;
  z-index: 999;
`;

export default Header;
