import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button, CloseButton, EmojiSpan, Loading } from 'components/common';

import EmptyState from 'components/EmptyState';
import pluralizeString from 'helpers/pluralizeString';

import VerifyTeacherAction from 'components/notifications/VerifyTeacherAction';
import TopicCalendar from 'components/TopicCalendar';
import SetWorkButton from 'components/setWork/SetWorkButton';

const NotificationsDropdown = ({ data, loading, onDismiss, onClick }) => {
  const schoolId = useSelector(s => s.school.currentSchool.schoolId);

  const processNotification = notification => {
    const { data, notificationType } = notification;

    switch (notificationType) {
      case 'AssignmentsDueCompletion':
        const percentageCompletionRate = Math.round(
          (data.completedCount / data.groupSize) * 100
        );
        const dueDate = moment(data.dueDate);
        return {
          message: (
            <>
              {percentageCompletionRate === 100 ? (
                <>
                  Great effort! <EmojiSpan emoji="🚀" ariaLabel="Rocket" /> 100%
                  of{' '}
                  <Link to={`/${schoolId}/classes/${notification.groupId}`}>
                    {data.groupCode}
                  </Link>{' '}
                  completed{' '}
                  <Link to={`/${schoolId}/assignments/${data.assignmentId}`}>
                    {data.topicName ?? data.quizName}
                  </Link>{' '}
                  already. Review their results now.
                  <EmojiSpan emoji="🔔" ariaLabel="Bell" />
                </>
              ) : (
                <>
                  {percentageCompletionRate >= 75 ? (
                    <>Almost there! {percentageCompletionRate}% of students</>
                  ) : percentageCompletionRate >= 50 ? (
                    <>
                      Halfway there.{' '}
                      <EmojiSpan emoji="👌" ariaLabel="OK Hand" />{' '}
                      {percentageCompletionRate}% of students
                    </>
                  ) : percentageCompletionRate > 0 ? (
                    <>
                      <EmojiSpan emoji="🚨" ariaLabel="Police Car Light" /> Just{' '}
                      {percentageCompletionRate}% of students
                    </>
                  ) : (
                    <>
                      <EmojiSpan emoji="🚨" ariaLabel="Police Car Light" /> None
                      of the students
                    </>
                  )}{' '}
                  in{' '}
                  <Link to={`/${schoolId}/classes/${notification.groupId}`}>
                    {data.groupCode}
                  </Link>{' '}
                  completed{' '}
                  <Link to={`/${schoolId}/assignments/${data.assignmentId}`}>
                    {data.quizName}
                  </Link>{' '}
                  <EmojiSpan emoji="📝" ariaLabel="Memo" />. It's due{' '}
                  {dueDate.format('DD/MM')}, end of day. Check below who needs a
                  reminder.{' '}
                  <EmojiSpan
                    emoji="🤗"
                    ariaLabel="Smiling Face with Open Hands"
                  />
                </>
              )}
              <Well>
                <TopicCalendar endDateUtc={dueDate} className="mr-2" />
                <div>
                  <h3 className="mb-0">
                    <Link to={`/${schoolId}/assignments/${data.assignmentId}`}>
                      {data.quizName}
                    </Link>
                  </h3>
                  {data.groupCode}
                </div>
              </Well>
            </>
          ),
          action: (
            <Button
              to={`/${schoolId}/assignments/${data.assignmentId}`}
              color="primary">
              {percentageCompletionRate === 100
                ? 'View results'
                : 'Check completion'}
            </Button>
          ),
          dismissable: true
        };
      case 'RetrievalPracticeProgress':
        return {
          message: (
            <>
              You need{' '}
              {data.assignmentsCount < 3 ? 3 - data.assignmentsCount : 0} more
              completed assignments for{' '}
              <Link to={`/${schoolId}/classes/${notification.groupId}`}>
                {data.groupCode}
              </Link>{' '}
              to unlock Retrieval Practice.{' '}
              <EmojiSpan emoji="🔓" ariaLabel="Padlock" /> It's powerful: each
              student gets to practice the topics they most need at the precise
              moment they need it. <EmojiSpan emoji="🙌" ariaLabel="Hands!" />
            </>
          ),
          action: <SetWorkButton color="primary" />,
          dismissable: true
        };
      case 'RetrievalPracticeUnlocked':
        return {
          message: (
            <>
              You've unlocked Retrieval Practice with{' '}
              <Link to={`/${schoolId}/classes/${notification.groupId}`}>
                {data.groupCode}
              </Link>
              . <EmojiSpan emoji="🔓" ariaLabel="Padlock" /> It's powerful: each
              student gets to practice the topics they most need at the precise
              moment they need it. <EmojiSpan emoji="🙌" ariaLabel="Hands!" />
            </>
          ),
          action: (
            <Button
              to={`/${schoolId}/classes/${notification.groupId}/retrieval-practice`}
              color="primary">
              Setup retrieval practice
            </Button>
          ),
          dismissable: true
        };
      case 'StudentJoinedClass_Teacher':
        return {
          message: (
            <>
              {data.studentName}{' '}
              {data.joinedCount
                ? `and ${data.joinedCount} ${pluralizeString(
                    'other',
                    data.joinedCount
                  )} have`
                : `has`}{' '}
              joined class{' '}
              <Link to={`/${schoolId}/classes/${notification.groupId}`}>
                {data.groupCode}
              </Link>
            </>
          ),

          dismissable: true
        };
      case 'ColleagueSharedScheme':
        return {
          message: (
            <>
              {data.teacherName} shared{' '}
              <Link to={`/${schoolId}/sow/${data.schemeId}`}>
                {data.schemeName} Scheme
              </Link>{' '}
              with you.
            </>
          ),
          dismissable: true
        };

      case 'VerifyTeacher':
        return {
          message: (
            <>
              <Link to={`/${schoolId}/teachers/${data.teacherId}`}>
                {data.fullName}
              </Link>{' '}
              requested verification as a teacher at your school.
            </>
          ),
          action: (
            <VerifyTeacherAction
              data={data}
              onDismiss={() => onDismiss(notification.notificationId)}
            />
          )
        };

      case 'PasswordReset':
        return {
          message: 'Your password was successfully updated.',
          dismissable: true
        };

      case 'RequestPermission':
        return {
          message: (
            <>
              <Link to={`/${schoolId}/teachers/${data.teacherId}`}>
                {data.fullName}
              </Link>{' '}
              requested permission to be an admin at your school
            </>
          ),
          action: (
            <Button
              to={`/${schoolId}/teachers/${data.teacherId}`}
              color="primary"
              size="small">
              Change permissions
            </Button>
          ),
          dismissable: true
        };

      case 'TeacherAcceptedInvitation':
        return {
          message: (
            <>
              <Link to={`/${schoolId}/teachers/${data.teacherId}`}>
                {data.teacherName}
              </Link>{' '}
              accepted your invitation to join your school.
            </>
          ),
          dismissable: true
        };

      case 'TeacherAddedToClass':
        return {
          message: (
            <>
              You have been added as teacher to class{' '}
              <Link to={`/${schoolId}/classes/${notification.groupId}`}>
                {data.groupCode}
              </Link>
            </>
          ),
          dismissable: true
        };

      case 'TeacherRemovedFromClass':
        return {
          message: (
            <>
              You have been removed as teacher from class{' '}
              <Link to={`/${schoolId}/classes/${notification.groupId}`}>
                {data.groupCode}
              </Link>
            </>
          ),
          dismissable: true
        };

      case 'VerifyTeacherApproved':
        return {
          message: (
            <>
              <Link to={`/${schoolId}/teachers/${data.teacherId}`}>
                {data.fullName}
              </Link>{' '}
              was added as a teacher at your school.
            </>
          ),
          dismissable: true
        };

      case 'VerifyTeacherDeclined':
        return {
          message: `${data.fullName} was declined access to your school`,
          dismissable: true
        };

      case 'RequestPermissionApproved':
        return {
          message: (
            <>
              <Link to={`/${schoolId}/teachers/${data.teacherId}`}>
                {data.fullName}
              </Link>{' '}
              was approved as an admin at your school.
            </>
          ),
          dismissable: true
        };

      case 'TeacherGeneratedGroupNotification':
        let studentMessageInfo = { teacherName: '', title: '' };
        if (data.info) {
          studentMessageInfo = JSON.parse(data.info);
        }
        return {
          message: (
            <>
              <strong>Message from Teacher:</strong>{' '}
              {studentMessageInfo.teacherName}
              <br />
              <strong>Group:</strong> {data.groupCode}
              <br />
              <strong>Message:</strong>
              <br />
              <span>
                <i>{studentMessageInfo.title}</i>
                <br />
                {data.body}
              </span>
            </>
          ),
          action: !notification.dateDismissed && (
            <Button
              color="primary"
              outline
              size="small"
              onClick={() => onDismiss(notification.notificationId)}>
              Mark As Read
            </Button>
          )
        };

      case 'TeacherGeneratedGroupNotificationSent':
        let teacherMessageInfo = { teacherName: '', title: '' };
        if (data.info) {
          teacherMessageInfo = JSON.parse(data.info);
        }
        return {
          message: (
            <>
              <strong>You sent the below message:</strong>
              <br />
              <strong>Group:</strong> {data.groupCode}
              <br />
              <strong>Message:</strong>
              <br />
              <span>
                <i>{teacherMessageInfo.title}</i>
                <br />
                {data.body}
              </span>
            </>
          ),
          dismissable: true
        };

      default:
        return false;
    }
  };

  const renderItem = notification => {
    const { message, action, dismissable } = processNotification(notification);

    if (!message) {
      return null;
    }

    return (
      <Notification
        key={notification.notificationId}
        onClick={() => onClick(notification.notificationType)}>
        {message}
        {action && <div className="mt-2">{action}</div>}
        <span className="mt-2 text-muted d-block text-sm">
          {moment(notification.dateCreated).fromNow()}
        </span>
        {dismissable && (
          <CloseButton
            onClick={() => onDismiss(notification.notificationId)}
            size="small"
            style={{
              position: 'absolute',
              right: '1rem',
              top: '50%',
              marginTop: '-1rem'
            }}
          />
        )}
      </Notification>
    );
  };

  if (loading) {
    return (
      <NotificationLoading>
        <Loading style={{ height: '2rem' }} />
      </NotificationLoading>
    );
  }

  const notifications = data.data;

  if (!notifications.length) {
    return (
      <EmptyState
        title="No notifications"
        imgSource={require('assets/images/eedi_empty.svg')}
        imgAlt="No new notifications"
      />
    );
  }

  return (
    <>
      {notifications.map(notification => renderItem(notification))}
      {loading && <Loading style={{ margin: '2rem' }} />}
    </>
  );
};

const Notification = styled.div`
  padding: 1.25rem 3rem 1.25rem 1.5rem;
  border-bottom: 1px solid var(--border-color);
  position: relative;
`;

const NotificationLoading = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
`;

const Well = styled.div`
  background-color: var(--blue-lightest);
  padding: 1rem;
  border-radius: 1rem;
  margin: 0.75rem 0;
  width: 100%;
  display: flex;
  align-items: center;
`;

export default NotificationsDropdown;
