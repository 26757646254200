import styled from 'styled-components';
import { Nav } from 'components/common';
import { NavLink } from 'react-router-dom';

const SubNav = ({ tabs, ...props }) => {
  return (
    <StyledNav {...props}>
      {tabs.map((tab, i) => {
        if (!tab) {
          return null;
        }
        return (
          <Nav.Item key={`${tab.id}-${i}`}>
            <SubNavLink
              to={tab.to}
              className={({ isActive, isPending }) =>
                isPending ? 'pending' : isActive ? 'active' : ''
              }
              disabled={tab.disabled}
              style={tab.style}
              end>
              {tab.label}
            </SubNavLink>
          </Nav.Item>
        );
      })}
    </StyledNav>
  );
};

const StyledNav = styled(Nav)`
  border-bottom: 1px solid var(--border-color);
`;

const SubNavLink = styled(NavLink)`
  margin-right: 0.5rem;
  padding: 0.5rem 0.5rem;
  color: var(--gray-dark);
  font-weight: 400;
  display: inline-block;
  border-bottom: 3px solid transparent;
  text-decoration: none;

  &:hover {
    border-color: var(--highlight-color) !important;
    color: var(--primary) !important;
    text-decoration: none;
  }

  &.active {
    border-color: var(--primary);
    color: var(--primary) !important;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }

  ${props =>
    props.disabled &&
    `
      border: none;
      color: var(--gray) !important;
      cursor: auto;
      &:hover {
        text-decoration: none;
        color: var(--gray) !important;
      }
  `}
`;

export default SubNav;
