import Chevron from 'assets/icons/Chevron';
import { Link } from 'react-router-dom';
import { Card } from 'components/common';
import styled from 'styled-components';

const StyledCardWrapper = styled(Card)`
  margin-bottom: 1rem;
  border-radius: 1rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 10px 0 rgba(0, 0, 0, 0.04);
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledListItemLink = styled(Link)`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 2rem;
  align-items: center;
  justify-content: center;
  color: var(--black);
  transition: all 0.2s ease-in-out;
  border-radius: 1rem;

  &:hover {
    background-color: var(--tint-color);
    padding-right: 1rem;
  }
`;

const ListItemLink = ({
  icon,
  contents,
  to,
  target,
  rel,
  onClick = () => {},
  ...props
}) => {
  return (
    <StyledCardWrapper {...props}>
      <StyledListItemLink to={to} target={target} rel={rel} onClick={onClick}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '90%'
          }}>
          {icon ?? null}
          {contents ?? null}
        </div>
        <Chevron className="ml-auto" />
      </StyledListItemLink>
    </StyledCardWrapper>
  );
};

export default ListItemLink;
