import { media } from 'styles';
import styled from 'styled-components';

const PageCard = styled.div.attrs({
  className: 'pagecard'
})`
  background-color: var(--white);
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${media.sm`
    border-radius: 1rem;
    min-height: auto;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  `};
`;

PageCard.Body = styled.div.attrs({
  className: 'pagecard-body'
})`
  padding: 1.5rem;
  ${media.sm`
    padding: 2.5rem;
  `};
`;

PageCard.Item = styled.div.attrs({
  className: 'pagecard-item'
})`
  border-bottom: solid 1px var(--border-color);
  &:last-child {
    border-bottom: 0;
  }
`;

PageCard.Header = styled.div.attrs({
  className: 'pagecard-header'
})`
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  ${media.sm`
    padding: 2.5rem 2.5rem 1rem 2.5rem;
  `};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  & + .pagecard-body {
    padding-top: 1rem;
  }
`;

PageCard.Footer = styled.div.attrs({
  className: 'pagecard-footer'
})`
  padding: 0 1.5rem 1.5rem 1.5rem;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;

  ${media.sm`
    padding: 0 2.5rem 2.5rem 2.5rem;
  `};
`;

export default PageCard;
