import { Button, Modal } from 'components/common';
import { useState } from 'react';
import styled from 'styled-components';

const ConfirmModal = ({
  title,
  message,
  onConfirm,
  onCancel = () => {},
  confirmButtonText,
  cancelButtonText,
  children,
  buttonColor = 'danger',
  formId,
  isOpen = true,
  isHideFooter = false
}) => {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const confirmOnce = () => {
    if (wasSubmitted) {
      return;
    }
    setWasSubmitted(true);
    onConfirm();
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header toggle={onCancel}>{title}</Modal.Header>
      <StyledModalBody>
        {message}
        {children}
      </StyledModalBody>
      {!isHideFooter && (
        <Modal.Footer>
          <Button color="link" className="text-dark" onClick={onCancel}>
            {cancelButtonText ?? 'No'}
          </Button>{' '}
          <Button
            form={formId}
            type="submit"
            color={buttonColor}
            onClick={confirmOnce}
            disabled={wasSubmitted}>
            {confirmButtonText ?? 'Yes'}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

const StyledModalBody = styled(Modal.Body)`
  padding-top: 0;
`;

export default ConfirmModal;
