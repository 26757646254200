import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 0.5em;
  font-weight: 500;
  font-size: 0.75rem;
  // It always seems a bit too low!
  transform: translateY(-0.125em);

  background: linear-gradient(86.77deg, #4353ff -45.62%, #c343ff 116.68%);
  color: white;

  &.light {
    background: white;
    color: #4353ff;
  }

  // Shine animation
  &:after {
    content: '';
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &.shine:after {
    animation: shine 1.55s ease;
  }

  @keyframes shine {
    0% {
      opacity: 0;
      top: -110%;
      left: -210%;
    }

    10% {
      opacity: 0.5;
    }

    90% {
      left: -30%;
    }

    100% {
      opacity: 0;
    }
  }
`;

export const EediPlusLogo = ({
  lightTheme = false,
  shinePeriodMs = [5000, 12000],
  ...props
}) => {
  const [playShineAnimation, setPlayShineAnimation] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    if (
      shinePeriodMs &&
      shinePeriodMs.length > 1 &&
      shinePeriodMs.reduce((a, b) => a + b) > 0 &&
      !playShineAnimation
    ) {
      const nextShineTimeMs =
        Math.random() * (shinePeriodMs[1] - shinePeriodMs[0]) +
        shinePeriodMs[0];

      setInterval(() => {
        if (isCancelled) {
          return;
        }

        setPlayShineAnimation(true);
      }, nextShineTimeMs);
    }

    return () => (isCancelled = true);
  }, [playShineAnimation, shinePeriodMs]);

  return (
    <Wrapper
      {...props}
      className={`${lightTheme ? 'light' : ''} ${
        playShineAnimation ? 'shine' : ''
      } ${props.className ?? ''}`}
      onAnimationEnd={() => setPlayShineAnimation(false)}>
      Plus
    </Wrapper>
  );
};
