/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { Logo, Navbar, Nav } from 'components/common';

const Navigation = ({
  top,
  bottom,
  leftComponent,
  rightComponent,
  middleComponent,
  ...props
}) => (
  <Navbar className={bottom ? 'fixed-bottom' : 'fixed-top'} {...props}>
    <Left>
      {leftComponent ? (
        leftComponent
      ) : !bottom ? (
        <Nav.Brand to="/">
          <Logo />
        </Nav.Brand>
      ) : null}
    </Left>
    <Middle>{middleComponent ? middleComponent : null}</Middle>
    <Right>{rightComponent ? rightComponent : null}</Right>
  </Navbar>
);

const Middle = styled.div`
  display: flex;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  text-overflow: ellipsis;
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

export default Navigation;
