import gql from 'graphql-tag';

export default gql`
  mutation joinGroup($teacherId: ID!, $groupId: ID!) {
    joinGroup(teacherId: $teacherId, groupId: $groupId) {
      teacherId
      groupIds
    }
  }
`;
