import Header from 'components/page/Header';
import { Page, SubNav, Main } from 'components/common';
import { Outlet } from 'react-router';
import { useSelector } from 'react-redux';
import ClassIndicator from 'components/ClassIndicator';

const MotivatePage = () => {
  const schoolId = useSelector(s => s.school.schoolId);
  return (
    <Page>
      <Header showNotifications={true}>
        <Header.Title>
          <h1>
            Motivate <ClassIndicator />
          </h1>
        </Header.Title>
        <SubNav
          tabs={[
            {
              label: 'Motivate',
              to: `/${schoolId}/motivate`
            },
            {
              label: 'Global School League',
              to: `/${schoolId}/motivate/school-league`
            },
            {
              label: 'My School League',
              to: `/${schoolId}/motivate/group-league`
            },
            {
              label: 'My Class League',
              to: `/${schoolId}/motivate/class-league`
            }
          ]}
        />
      </Header>
      <Main>
        <Outlet />
      </Main>
    </Page>
  );
};

export default MotivatePage;
