const types = {
  student: {
    typename: 'student',
    keys: ['studentId', 'userId']
  },
  liveClass: {
    typename: 'liveClass',
    keys: ['sequence', 'yearGroup']
  },
  userPreferences: {
    typename: 'userPreferences',
    keys: ['userId']
  },
  country: {
    typename: 'country',
    keys: ['countryId']
  },
  prizeDraw: {
    typename: 'prizeDraw',
    keys: ['prizeDrawRequirementId']
  },
  prizeDrawEntry: {
    typename: 'prizeDrawEntry',
    keys: ['userTransactionId']
  },
  wordEediDictionaries: {
    typename: 'wordEediDictionaries',
    keys: []
  },
  numberEediDictionaries: {
    typename: 'numberEediDictionaries',
    keys: []
  },
  assignmentInsights: {
    typename: 'assignmentInsight',
    keys: ['assignmentId']
  },
  topicInsights: {
    typename: 'topicInsights',
    keys: ['quizId']
  },
  quiz: {
    typename: 'quiz',
    keys: ['quizId']
  },
  quizInsights: {
    typename: 'quizInsights',
    keys: ['quizId']
  },
  collectionSearch: {
    typename: 'collectionSearch',
    keys: ['topicPathwayCollectionId']
  },
  topicPathwayQuiz: {
    typename: 'topicPathwayQuiz',
    keys: ['quizId', 'topicPathwayQuizId', 'shortCode']
  },
  retrievalPractice: {
    typename: 'retrievalPractice',
    key: ['groupId']
  },
  groupAnalytics: {
    typename: 'groupAnalytics',
    keys: ['groupId']
  },
  studentAnalytics: {
    typename: 'studentAnalytics',
    keys: ['studentId', 'userId']
  },
  notifications: {
    typename: 'notifications',
    keys: ['userId']
  },
  quizListSearch: {
    typename: 'quizListSearch',
    keys: ['quizId']
  },
  topicTags: {
    typename: 'topicTags',
    keys: ['topicTagId']
  },
  getQuizInferences: {
    typename: 'getQuizInferences',
    keys: ['quizId']
  }
};

export default types;
