import { Outlet } from 'react-router';
import styled from 'styled-components';
import { media } from 'styles';
import Navigation from 'components/Navigation';
import { useLocation } from 'react-router-dom';
import { Button, Logo, Nav } from 'components/common';
import { MARKETING_SITE_URL } from 'config/settings';

const SignupLayout = () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');

  const isLoginPage = pathParts[1] !== 'signup';

  return (
    <Body>
      <Navigation
        leftComponent={
          <Nav.Brand to={MARKETING_SITE_URL}>
            <Logo />
          </Nav.Brand>
        }
        rightComponent={
          isLoginPage ? (
            <div className="text-right">
              <span className="mr-3">Don't have an account?</span>
              <Button to="/signup" color="primary" outline>
                Sign up
              </Button>
            </div>
          ) : (
            <div className="text-right">
              <span className="mr-3">Already have an account?</span>
              <Button to="/login" color="primary" outline>
                Login
              </Button>
            </div>
          )
        }
      />
      <Outlet />
    </Body>
  );
};

const Body = styled.div`
  min-height: 100vh;
  padding-top: 9rem;
  ${media.sm`
    background: linear-gradient(308.66deg, var(--indigo-light) 3.96%, var(--purple-light) 100%);
    background-size: cover;
  `}
`;

export default SignupLayout;
