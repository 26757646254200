import gql from 'graphql-tag';

export default gql`
  mutation updateTeacher(
    $userId: ID!
    $teacherId: ID!
    $teacher: TeacherInput
    $user: UserInput
  ) {
    updateTeacher(
      userId: $userId
      teacherId: $teacherId
      teacher: $teacher
      user: $user
    ) {
      teacherId
      isSchoolAdministrator
      schoolMembershipStatus
      title
      firstName
      lastName
      departmentIds
      email
    }
  }
`;
