export const toggleNav = () => ({
  type: 'TOGGLE_NAV'
});

export const setLoadingState = loadingState => ({
  type: 'SET_LOADING_STATE',
  loadingState
});

export const closedSearchParamModal = () => ({
  type: 'CLOSED_SEARCH_PARAM_MODAL'
});
