import { SettingsIcon } from 'assets/icons';
import FilledCircle from 'assets/icons/FilledCircle';
import { Button, Card } from 'components/common';
import { useSelector } from 'react-redux';
import { colors } from 'styles';

const StudentManagementMoved = () => {
  const schoolId = useSelector(s => s.school.schoolId);

  return (
    <Card>
      <Card.Item>
        <FilledCircle className="mr-3" size={5} color={colors.purpleLighter}>
          <SettingsIcon size="4rem" color={colors.purpleLight} />
        </FilledCircle>
        <div className="flex-fill">
          <h2>We've moved things around</h2>
          <h4 className="subtitle">
            You can now manage student accounts in the settings area
          </h4>
        </div>
        <Button to={`/${schoolId}/settings/students`}>Student settings</Button>
      </Card.Item>
    </Card>
  );
};

export default StudentManagementMoved;
