import styled from 'styled-components';

const Chevron = ({
  fill = 'var(--black)',
  angle = 0,
  onClick = () => {},
  cursor = 'auto',
  ...props
}) => (
  <StyledChevron
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={fill}
    cursor={cursor}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-right"
    onClick={() => onClick()}
    {...props}>
    <polyline transform={`rotate(${angle} 12 12)`} points="9 18 15 12 9 6" />
  </StyledChevron>
);

const StyledChevron = styled.svg`
  cursor: ${props => props.cursor};
  color: ${props => props.fill};
`;

export default Chevron;
