const objectToSearchParams = src => {
  const searchParams = new URLSearchParams();
  Object.keys(src).forEach(d => {
    if (Array.isArray(src[d])) {
      src[d].forEach(e => searchParams.append(d, e));
    } else {
      searchParams.append(d, src[d]);
    }
  });

  return searchParams;
};

export { objectToSearchParams };
