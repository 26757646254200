import styled from 'styled-components';

const Well = styled.div`
  background-color: var(--blue-lightest);
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
`;

export default Well;
